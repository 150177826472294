<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <div style="width: 80%; max-width: 500px; margin-bottom: 40px">
      <v-img :src="require(`@/assets/logo.png`)" />
    </div>
    <v-row justify="center">
      <v-col md="6">
        <v-slide-y-transition appear>
          <base-material-card
            v-if="!forgotPassword"
            color="primary"
            light
            class="px-5 py-3"
            style="text-align: right"
          >
            <template v-slot:heading>
              <h1 class="display-2 font-weight-bold">
                Login
              </h1>
            </template>

            <v-card-text class="text-center">
              <form @submit.prevent="doLogin()">
                <v-text-field
                  v-model="data.username"
                  color="secondary"
                  :label="$t('username-or-email')"
                  prepend-icon="mdi-email"
                />

                <v-text-field
                  v-model="data.password"
                  color="secondary"
                  :label="$t('password')"
                  type="password"
                  prepend-icon="mdi-lock-outline"
                />

                <div
                  v-if="error"
                  style="margin-bottom: 20px; color: red"
                >
                  {{ $t(error) }}
                </div>

                <v-btn
                  large
                  color="primary"
                  depressed
                  type="submit"
                >
                  {{ $t('login') }}
                </v-btn>
              </form>
            </v-card-text>
            <a
              href="#"
              @click.prevent="toggleForgotPassword"
            >{{ $t('forgot-password') }}</a>
          </base-material-card>

          <base-material-card
            v-else
            color="secondary"
            light
            class="px-5 py-3"
            style="text-align: right"
            noheader
          >
            <v-card-text
              v-if="!forgotPasswordSent"
              class="text-center"
            >
              <form @submit.prevent="recoverPassword()">
                <v-text-field
                  v-model="data.username"
                  color="secondary"
                  :label="$t('username-or-email')"
                  prepend-icon="mdi-email"
                />

                <v-btn
                  large
                  color="primary"
                  type="submit"
                >
                  {{ $t('recover-password') }}
                </v-btn>
              </form>
            </v-card-text>

            <v-card-text
              v-if="forgotPasswordSent"
              class="text-center"
            >
              <v-text>
                {{ $t('dialog.great-you-will-receive-an-email') }}
              </v-text>
              <br>
              <br>
              <v-text style="margin-top: 15px">
                {{ $t('dialog.this-should-not-take-longer-than') }}
              </v-text>
            </v-card-text>

            <a
              v-if="forgotPassword"
              href="#"
              @click.prevent="toggleForgotPassword"
            >
              {{ $t('back-to-login') }}
            </a>
            <a
              v-if="!forgotPassword"
              href="#"
              @click.prevent="toggleForgotPassword"
            >
              {{ $t('forgot-password') }}
            </a>
          </base-material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { types } from '@/store/utils/resource'
  import authService from '../../authService'
  export default {
    name: 'PagesLogin',

    data: () => ({
      forgotPassword: false,
      forgotPasswordSent: false,
      data: {
        username: '',
        password: '',
      },
      error: '',
    }),

    mounted () {
      this.$store.commit('resetMain')
      this.$store.commit('resetCompany')
      this.$store.commit(`companyVehicleStore/${types.RESET}`)
      this.$store.commit(`reportStore/${types.RESET}`)
      this.$store.commit(`certificationAuthorityStore/${types.RESET}`)
      this.$store.commit(`certificateStore/${types.RESET}`)
    },
    methods: {
      doLogin () {
        if (this.data.username.length && this.data.password.length) {
          authService
            .login(this.data)
            .then((result) => {
              if (result.status === 200) {
                const companyInviteToken = this.$route.query.token
                if (companyInviteToken) {
                  this.$router.push(`/acceptCompanyInvitation/${companyInviteToken}`, () => {})
                } else {
                  this.$router.push('/app', () => {})
                }
              } else {
                console.error('Auth did not receive 200', result)
              }
            })
            .catch((error) => {
              if (error.response.data === 'user not authorized') {
                this.error = 'dialog.user-not-authorized'
              } else if (error.response.data === 'user not activated') {
                this.error = 'dialog.user-not-activated'
              } else if (error.response.data === 'user not found') {
                this.error = 'dialog.user-or-password-wrong'
              }
            })
        }
      },
      recoverPassword () {
        authService.recoverPassword(this.data).then(() => {
          this.forgotPasswordSent = true
        })
      },
      toggleForgotPassword () {
        this.forgotPassword = !this.forgotPassword
        this.forgotPasswordSent = false
      },
    },
  }
</script>
